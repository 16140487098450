.sparepart-edit-container{
    position: relative;
    background-color: #e1b382;
    min-height:100vh;
    padding:20px;  
}
.sparepart-edit-container h2{
    text-align: center;
}
.sparepart-edit-container .sparepart-edit{
    display: grid;
    grid-template-columns: 5em 13em ;
    justify-content: center;
    margin-top: 10px ;
}
.sparepart-edit-container .sparepart-edit p{
    line-height: 35px;
}
.sparepart-edit-container .sparepart-edit input{
    width: 100%;
    padding: 7px;
}
.sparepart-edit-container .sparepart-edit-button{
    display: flex;
    justify-content: center;
    margin-top: 20px ;

}
.sparepart-edit-container .sparepart-edit-button button{
    width: 100px;
    height: 30px;
    font-size: 1.1rem;
    background-color: black;
    color: aliceblue;            
}