*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}
nav{
    background-color:  #12343b;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

nav .logo{
    width: 200px;
    position:relative;
    height: 100%;
    padding: 10px;
}
nav .logo span{
    color: aliceblue;
    line-height: 60px;
    font-size:1.5rem;
    font-weight: 800;
}

nav .logo span a{
    text-decoration: none;
    color: aliceblue;

}
nav .links{
    background-color:#12343b;
    height:100%;
    width: 300px;
}

nav ul{
    position: relative;
}
nav ul li{
    list-style-type: none;
    display:inline-block;
    line-height:80px;
    margin-left:15px;
    font-size:1.1rem;
}
nav ul li a{
    color:aliceblue;
    text-decoration:none;
    font-size:1.2rem;
    font-weight: 800;
    border-bottom: 2px solid salmon;
}
nav ul li a:hover{
    font-size:1.3rem;
}

@media only screen and (max-width: 550px) {
    nav{
        display: block;
        width: 100%;
    }
    nav .logo {
        display: none;
        position: relative;
        width: 100%;
    }
    nav .links{
        display: flex;
        justify-content: space-around;

        width: 100%;

    }
}
