.type-container{
    background-color: #e1b382;
    min-height: 100vh;
    padding:20px;   

}

.type-container h2{
    text-align: center;
    color:aliceblue;
}

.type-container .type-form{
    display: flex;
    justify-content: center;
    position: relative;
    margin-top:20px;
    width:100%;
    height: 30px;

}
.type-container .type-form input{
    width:300px;
    height: 100%;
    text-align: center;
}
.type-container .type-form input::placeholder{
    text-align: center;
    font-size:1.2rem

}
.type-container .type-form input:focus{
    text-align: center;    
    font-size:1.2rem
}
.type-container .type-form input:focus::placeholder{
    color: transparent;    
}


.type-container .type-form{
    display: flex;
    justify-content: center;
    margin-top:20px;
}
.type-container .type-button{
    display: flex;
    justify-content: center;
    margin-top:20px;
}
.type-container .type-button button{
    width: 100px;
    height: 30px;
    font-size: 1.1rem;
    background-color: black;
    color: aliceblue;    
}



