.login-div{
    background-color: #e1b382;
    min-height: 100vh;
    padding:20px;   
}

.login-div h2{
    text-align:center;
    color: aliceblue;
}
.login-div p{
    text-align:center;
    color: #f00;
    position:relative;

}

.login-div .login-container{
    display: flex;
    justify-content: center;
    position:relative;
    width:100%;
    margin-top: 20px;
}

.login-div .login-container .login-input{
    padding: 5px;
    width: 300px;
    height: 40px;
}
.login-div .login-container .login-input input{
    width: 100%;
    height: 100%;
    text-align: center;
}
.login-div .login-container .login-input input::placeholder{
    text-align: center;
    font-size:1.2rem
}
.login-div .login-container .login-input input:focus{
    text-align: center;    
    font-size:1.2rem

}
.login-div .login-container .login-input input:focus::placeholder{
    color: transparent;    
}
.login-div .login-container .login-button{
    display: flex;
    justify-content: center;
    width: 100%;    
}
.login-div .login-container .login-button button{
    width: 100px;
    height: 30px;
    font-size: 1.1rem;
    background-color: black;
    color: aliceblue;    
}

