.sparepart-detail-container{
    background-color: #e1b382;
    min-height: 100vh;
    color: #fff;    

}
.sparepart-detail-container a{
    position: absolute;
    top: 130px;
    right: 20px;
    font-size: 1.2rem;
    text-decoration: none;
}
.sparepart-detail-container h2{
    padding-top:20px;
    text-align: center;
    color: #fff;    
}

.sparepart-detail-container .sparepart-detail{
    display: block;
    margin-top: 30px;
    background-color: #2d545e;    
    padding: 10px;
    
}
.sparepart-detail-container .sparepart-detail .sparepart-hedaer{
    margin-top:20px;
    display: grid;
    grid-template-columns: 50% 50% ;
    padding-left:3px;
    margin-left: 7px;    

}
.sparepart-detail-container .sparepart-detail .sparepart-hedaer h3{
    margin-top:20px;

}
.sparepart-detail-container .sparepart-detail .sparepart-image{
    display: flex;
    justify-content: center;    
    height: 300px;
    width: 100%;
    background-color:#2d545e;
    margin-top: 20px;
}
.sparepart-detail-container .sparepart-detail .sparepart-image img{
    
    height: 100%;
    width: 300px;
    object-fit: contain;
    
}