.home-container{
  position: relative;
  background-color: #e1b382;
  min-height:100vh;
}

.home-container .box{
  margin-top: 40px;
  margin-left: 60px;
  margin-right: 20px;
  width: 400px;
  height: 140px;
  background-color: #2d545e;
  display:inline-block;
  box-shadow: 5px 10px 5px 10px #c89666;
}

.home-container .box h2{
  text-align: center;
  line-height: 140px;
  color: #fff;
  font-weight: 900;
  font-size: 2rem;
  }
@media only screen and (max-width: 550px) {
.home-container .box{
  width: 90%;  
  margin-left: 20px;
  
}
      
}
.loading-page{
    display: flex;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    background-color: #e1b382;  
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-top: 100px;
}
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  