.sparepart-container{
    background-color: #e1b382;
    min-height: 100vh;
    padding:20px;   
}
.sparepart-container h2{
    text-align: center;
    color: #fff;    
}
.sparepart-container .search-input{
    display : flex;
    justify-content: center;
    margin: 20px 0;
    height: 30px;
}
.sparepart-container .search-input input{
    width: 300px;
}
.sparepart-container .sparepart-list{
    width: 100%;
   
}
.sparepart-container .sparepart-list .sparepart-detail{
    width: 450px;
    background-color: #2d545e;
    height: 500px;
    margin: 5px;
    display: inline-block;    
}
.sparepart-container .sparepart-list .sparepart-detail .sparepart-header{
    height: 90px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    border-bottom: 2px solid #fff;
}


.sparepart-container .sparepart-list .sparepart-detail .sparepart-header a{
    color: #fff;
    text-decoration: none;
}
.sparepart-container .sparepart-list .sparepart-detail .sparepart-info{
    height: 50px;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 70% ;
    padding-left:3px;
    margin-left: 7px;    
    color: #fff;
    font-size: 1.1rem;
}

.sparepart-container .sparepart-list .sparepart-detail .sparepart-info :nth-child(1){
    margin-top: 10px;
}
.sparepart-container .sparepart-list .sparepart-detail .sparepart-info :nth-child(2){
    margin-top: 10px;
}
.sparepart-container .sparepart-list .sparepart-detail .sparepart-image{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.sparepart-container .sparepart-list .sparepart-detail img{
    width: 240px;
    height: 240px;
    object-fit: contain;

    
}


@media only screen and (max-width: 550px) {
    .sparepart-container .sparepart-list .sparepart-detail{
        width: 100%;
    }
}









