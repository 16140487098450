.spare-type-container{
    background-color: #e1b382;
    min-height: 100vh;
    padding:20px;   
}
.spare-type-container h2{
    text-align: center;
    color: #fff;
}

.spare-type-container .spare-type-list{
    position: relative;
    margin-top:20px;
    padding: 10px;
}

.spare-type-container .spare-type-list .spare-type{
    display:inline-block;
    min-height: 60px;
    width:350px;
    background-color: #2d545e;
    margin: 5px;    
}
.spare-type-container .spare-type-list .spare-type h3{
    font-size: 1rem;
    margin-left: 5px;
    word-break: break-all;
    line-height:60px;
    color: #fff;
}
@media only screen and (max-width:550px)  {
    .spare-type-container .spare-type-list .spare-type{
        display:block;
        width:100%;
    }
    
}