.spare-part-create{
    background-color: #e1b382;
    min-height: 100vh;
    padding:20px;   
}

.spare-part-create h2{
    text-align: center;
    color: aliceblue;
}

.spare-part-create .spare-type-list{
    display: flex;
    justify-content: center;
    position:relative;
    width:100%;
    margin-top: 20px;
}
.spare-part-create .spare-type-list .inputs{
    padding: 5px;
    width: 300px;
    height: 40px;

}
.spare-part-create .spare-type-list .inputs select{
    width: 100%;
    height: 100%;
    text-align: center;
}
.spare-part-create .spare-type-list .inputs input{
    width: 100%;
    height: 100%;
    text-align: center;
}
.spare-part-create .spare-type-list .sparepart-button{
    display: flex;
    justify-content: center;
}
.spare-part-create .spare-type-list .sparepart-button button{
    width: 100px;
    height: 30px;
    font-size: 1.1rem;
    background-color: black;
    color: aliceblue;            
}
